<template>
	<div v-if="context.help" :id="`${context.id}-help`" :class="context.classes.help">
		<button
			type="button"
			class="button open"
			:data-toggle="`${context.id}-hint`"
			:title="`${translations.field_explanation_open} ${context.label}`"
		>
			{{ translations.field_explanation_open }} {{ context.label }}
		</button>
		<div :id="`${context.id}-hint`" class="hint" data-toggler=".expanded" tabindex="0">
			{{ context.help }}

			<button
				type="button"
				class="button close"
				:data-toggle="`${context.id}-hint`"
				:title="translations.field_explanation_close"
			>
				{{ translations.field_explanation_close }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		context: {
			type: Object,
			required: true,
		},
	},
	computed: {
		translations() {
			const currentLanguage = document
				.getElementsByTagName('html')[0]
				.getAttribute('lang')
				.substring(0, 2);

			return currentLanguage === 'en' ? WP.en : WP.nl;
		},
	},
};
</script>
