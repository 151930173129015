<template>
    <div>
        <FormulateForm name="contact-form" v-model="formValues" :keep-model-data="true" ref="form">
            <div v-if="!success">
                <p>{{ translations.required_fields }}</p>
            </div>

            <!-- Success message -->
            <div v-if="verify && success" class="success-message" role="status">
                <p>
                    {{ translations.success_message }}
                </p>
            </div>

            <!-- Error message -->
            <div v-if="verify && !success && errors.length > 0" class="errors-message" role="alert">
                <p>{{ translations.error_message }}</p>
            </div>

            <div class="form-group" v-if="!success && !verify">
                <FormulateInput
                    name="type"
                    ref="type"
                    :options="{ question: translations.option_question, complaint: translations.option_complaint }"
                    type="select"
                    :placeholder="translations.option_placeholder"
                    :label="translations.question_or_complaint"
                    validation="required"
                />
                <FormulateInput
                    name="first_name"
                    ref="first_name"
                    :label="translations.first_name"
                    validation="optional"
                    autocomplete="given-name"
                />
                <FormulateInput
                    name="last_name"
                    ref="last_name"
                    :label="translations.last_name"
                    validation="optional"
                    autocomplete="family-name"
                />
                <FormulateInput
                    name="email"
                    ref="email"
                    :label="translations.email"
                    validation="^required|email"
                    error-behavior="blur"
                    :help="translations.email_help"
                    autocomplete="email"
                />
                <FormulateInput
                    v-if="showIfComplaint"
                    name="phone"
                    :label="translations.phone"
                    validation="required"
                    error-behavior="blur"
                    :help="translations.phone_help"
                    autocomplete="tel"
                />
                <FormulateInput
                    name="reachability"
                    ref="reachability"
                    v-model="reachability"
                    v-if="showIfComplaint"
                    :options="{
                        morning: translations.reachability_option_morning,
                        noon: translations.reachability_option_noon,
                        evening: translations.reachability_option_evening,
                    }"
                    type="radio"
                    :label="translations.reachability"
                    validation="required"
                />
                <FormulateInput name="subject" ref="subject" :label="translations.subject" validation="required" />
                <FormulateInput
                    name="message"
                    ref="message"
                    type="textarea"
                    :label="translations.message"
                    validation="required|max:5000,length"
                    :help="translations.message_help"
                    cols="40"
                    rows="10"
                />
                <FormulateInput
                    name="terms"
                    ref="terms"
                    type="checkbox"
                    :label="translations.agreement_statement"
                    label-position="before"
                    validation="^required"
                    :wrapper-class="['terms']"
                    :outer-class="['terms-input']"
                    :options="{ terms: translations.agreement_statement_option }"
                />
                <div class="form-buttons">
                    <FormulateInput type="button" @click="runValidation">
                        {{ translations.verify_your_information }}
                    </FormulateInput>
                </div>
            </div>

            <div class="scroll-container" :class="{ check: verify && !success }" v-if="verify">
                <div class="form-check">
                    <div class="title">
                        {{ translations.title }}
                    </div>
                    <div class="item">
                        <div>
                            {{ translations.question_or_complaint }}
                        </div>
                        <div>
                            {{
                                formValues.type === 'question'
                                    ? translations.option_question
                                    : translations.option_complaint
                            }}
                        </div>
                    </div>
                    <div class="item">
                        <div>{{ translations.first_name }}</div>
                        <div>{{ formValues.first_name }}</div>
                    </div>
                    <div class="item">
                        <div>{{ translations.last_name }}</div>
                        <div>{{ formValues.last_name }}</div>
                    </div>
                    <div class="item">
                        <div>{{ translations.email }}</div>
                        <div>{{ formValues.email }}</div>
                    </div>
                    <div class="item" v-if="formValues.phone">
                        <div>{{ translations.phone }}</div>
                        <div>{{ formValues.phone }}</div>
                    </div>
                    <div class="item" v-if="formValues.reachability">
                        <div>{{ translations.reachability }}</div>
                        <div>{{ translations['reachability_option_' + formValues.reachability] }}</div>
                    </div>
                    <div class="item">
                        <div>{{ translations.subject }}</div>
                        <div>{{ formValues.subject }}</div>
                    </div>
                    <div class="item">
                        <div>{{ translations.message }}</div>
                        <div>{{ formValues.message }}</div>
                    </div>
                    <div class="item">
                        <div>{{ translations.agreement_statement }}</div>
                        <div>
                            {{ translations.agreement_statement_option.replace(/(<([^>]+)>)/gi, '') }}
                        </div>
                    </div>
                </div>

                <div class="form-buttons" v-if="!success">
                    <div>
                        <FormulateInput type="button" @click="verify = false" :disabled="loading">
                            {{ translations.edit }}
                        </FormulateInput>
                        <FormulateInput type="button" @click="submit" :disabled="loading">
                            {{ translations.submit }} <span v-if="loading" class="loader"></span>
                        </FormulateInput>
                    </div>
                </div>
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { getApiRoute } from './helpers/api.js';

export default {
    name: 'Form',
    data() {
        return {
            currentLanguage: 'nl',
            verify: false,
            loading: false,
            success: false,
            errors: [],
            formValues: {},
            reachability: 'morning',
            toggleText: true,
        };
    },
    computed: {
        translations() {
            return this.currentLanguage === 'en' ? WP.en : WP.nl;
        },
        showIfComplaint() {
            // Reinitialize Foundation so that Toggler works correctly.
            $(document).foundation();

            return this.formValues.type === 'complaint';
        },
    },
    watch: {
        formValues(newFormValues, oldFormValues) {
            // Save form values to localStorage on every change.
            this.saveStorage(newFormValues);
        },
        'formValues.type': function (newType, oldType) {
            if (newType === 'question') {
                delete this.formValues.phone;
                delete this.formValues.reachability;
            }
        },
    },
    created() {
        // Load stored form values on loading form.
        const storedFormValues = this.openStorage();
        if (storedFormValues) {
            this.formValues = storedFormValues;
        }

        this.currentLanguage = document.getElementsByTagName('html')[0].getAttribute('lang').substring(0, 2);
    },
    async mounted() {
        // Reinitialize Foundation so that Toggler works correctly.
        $(document).foundation();
    },
    methods: {
        async submit() {
            // Prevent form from executing multiple times.
            if (this.loading === true) {
                return;
            }

            // Set states.
            this.loading = true;
            this.success = false;

            // Submit form.
            try {
                const { data } = await this.axios.post(getApiRoute('contact-form'), {
                    form: this.formValues,
                    translations: this.translations,
                });

                if (data.success) {
                    // Set states.
                    this.success = true;
                    $(document).trigger('ggd_form_submitted');

                    // Clear form and localStorage.
                    this.clearFormValues();
                } else {
                    // Set states.
                    this.errors = data.data;
                }
            } catch (error) {
                // Set states.
                this.errors = [error];
            } finally {
                // Set states.
                this.loading = false;

                // Scroll to success or errors message.
                this.$refs.form?.$el.scrollIntoView({ behavior: 'smooth' });
            }
        },
        openStorage() {
            return JSON.parse(localStorage.getItem('formValues'));
        },
        saveStorage(form) {
            localStorage.setItem('formValues', JSON.stringify(form));
        },
        clearFormValues() {
            // this.formValues = {};
            localStorage.removeItem('formValues');
        },
        async runValidation() {
            const form = this.$formulate.registry.get('contact-form');
            form.formSubmitted();

            const hasErrors = await form.hasValidationErrors();
            if (!hasErrors) {
                this.verify = true;
            }
        },
    },
};
</script>
